<template>
<b-card>
    <validation-observer ref="simpleRules">
        <b-form>
            <!-- Section Informations -->
            <h4>{{$t('Informations')}}</h4>
            <span class="font-weight-bold">{{$t('The fields with (*) are required')}}</span>
            <hr class="mb-2">
            <b-row>
                <!-- Field: Name -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Name')+'*'" label-for="name">
                        <validation-provider #default="{ errors }" name="name" rules="required|min:3|max:50">
                            <b-form-input v-model="pointData.name" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Customer 
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('Customer')" label-for="customer">
                        <vue-autosuggest v-if="!pointData.id" v-model="searchQuery" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                            <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                            </template>
                        </vue-autosuggest>

                        <b-form-input v-if="pointData.id" id="customer" v-model="searchQuery" disabled />
                    </b-form-group>
                </b-col>-->

                <!-- Field: Alert In -->
                <b-col cols="12" md="4" class="mb-2">
                    <div class="d-flex justify-content-start align-items-center mt-2">
                        <span class="font-weight-bold"> {{ $t('Alert In')}}</span>
                        <b-form-checkbox v-model="pointData.alertIn" name="alert-in" class="mr-0 ml-1" switch inline />
                    </div>
                   
                </b-col>

                <!-- Field: Alert Out -->
                <b-col cols="12" md="4" class="mb-2">
                    <div class="d-flex justify-content-start align-items-center mt-2">
                        <span class="font-weight-bold"> {{ $t('Alert Out')}}</span>
                        <b-form-checkbox v-model="pointData.alertOut" name="alert-out" class="mr-0 ml-1" switch inline />
                    </div>
                    
                </b-col>
            </b-row>

        </b-form>
    </validation-observer>

    <!-- Section Informations -->
    <h4 class="mt-1">{{$t('Draw Point Of Interest')}}</h4>
    <hr style="margin-bottom: 16px;">
    <l-map ref="map" style="min-height: 500px; max-height: 500px;" :zoom="zoom" :center="center">
        <l-control>
            <!-- size -->
            <b-button v-show="save" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="btn-icon" @click.prevent="validationForm" v-b-tooltip.hover title="Save">
                <feather-icon icon="SaveIcon" />
            </b-button>
        </l-control>
        <l-tile-layer :url="url"></l-tile-layer>
    </l-map>

</b-card>
</template>

<script>
import L from "leaflet";
import LDraw from 'leaflet-draw';
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LControl,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import LDrawToolbar from 'vue2-leaflet-draw-toolbar';

import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
} from 'bootstrap-vue'
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BFormCheckbox,
} from 'bootstrap-vue'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import router from '@/router'

import store from '@/store'
import pointStoreModule from './pointStoreModule'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'
import {
    VueAutosuggest
} from 'vue-autosuggest'

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    email,
    confirmed,

    between,
    alpha,
    integer,
    password,
    min,
    max,
    digits,
    alphaDash,
    length,
} from '@validations'

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,

        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BNavItemDropdown,
        BDropdownItem,
        BImg,
        BFormCheckbox,

        Cleave,
        vSelect,
        VueAutosuggest,

        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LPopup,
        LControl,
        LDrawToolbar,
        LDraw,
        L,

    },
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

            circleData: {
                lat: 0,
                lng: 0,
                radius: 0
            },
            polygonData: {
                latlngs: []
            },
            save: false,
            alertOptions: [{
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                },
            ],

            datasuggest: [],
            filteredOptions: [],
            inputProps: {
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: "Search for your customer ...",
            },
            limit: 3,
            selected: null,

            required,
            email,
            confirmed,

            between,
            alpha,
            integer,
            password,
            min,
            max,
            digits,
            alphaDash,
            length,

            pointData: {
                id: '',
                name: "",
                customerId: null,
                lat: 0,
                lng: 0,
                radius: 0,
                alertIn: true,
                alertOut: true,
            },
            searchQuery: ''

        }
    },
    computed: {

    },
    mounted() {
        if (!router.currentRoute.params.id) this.addEditControlMap()
        if (router.currentRoute.params.id) this.fetchPoint()
    },
    methods: {

        drawCircle(lat, lng, r) {

            //L.circle([-19.7412, -44.14872], 600).addTo(map);

            this.$nextTick(() => {
                const map = this.$refs.map.mapObject;
                const drawnItems = new L.FeatureGroup();
                map.addLayer(drawnItems);
                const circle = L.circle([lat, lng], r);
                circle.setStyle({color: '#9c27b0'})
                drawnItems.addLayer(circle);

                //const editableLayers = new window.L.FeatureGroup().addTo(map);
                const drawControl = new window.L.Control.Draw({
                    position: 'topright',
                    draw: {
                        polyline: false,
                        polygon: false,
                        rectangle: false,
                        circle: true,
                        marker: false,
                        circlemarker: false
                    },
                    edit: false
                });
                const drawEditOnly = new window.L.Control.Draw({
                    position: 'topright',
                    draw: false,
                    edit: {
                        featureGroup: drawnItems, //REQUIRED!!
                        remove: true
                    }
                });

                if (router.currentRoute.params.id) {
                    this.save = true
                    map.addControl(drawEditOnly);
                } else map.addControl(drawControl);

                map.on(window.L.Draw.Event.CREATED, (e) => {
                    // const type = e.layerType;
                    e.layer.options.color = '#9c27b0'
                    const layer = e.layer;

                    
                    this.pointData.lat = 0
                    this.pointData.lng = 0
                    this.pointData.radius = 0

                   
                    
                    if (layer instanceof L.Circle) {
                        this.pointData.radius = layer.getRadius()
                        const latlng = layer.getLatLng()
                        this.pointData.lat = latlng.lat
                        this.pointData.lng = latlng.lng
                        

                    }
                    

                    drawnItems.addLayer(layer);

                    //drawControl.removeFrom(map);
                    map.removeControl(drawControl);
                    map.addControl(drawEditOnly);

                    this.save = true

                    // Do whatever else you need to. (save to db, add to map etc)
                });

                map.on("draw:edited", (e) => {
                    const layers = e.layers.getLayers();

                    
                    this.pointData.lat = 0
                    this.pointData.lng = 0
                    this.pointData.radius = 0

                    const layer = layers[0]

                    this.pointData.radius = layer.getRadius()
                    const latlng = layer.getLatLng()
                    this.pointData.lat = latlng.lat
                    this.pointData.lng = latlng.lng
                    
                    this.save = true
                })

                map.on("draw:deleted", (e) => {

                    if (drawnItems.getLayers().length === 0) {

                        
                        this.pointData.lat = 0
                        this.pointData.lng = 0
                        this.pointData.radius = 0

                        
                        this.save = false
                        map.removeControl(drawEditOnly);
                        map.addControl(drawControl);
                    };
                });

                this.$refs.map.mapObject.setView([lat, lng], 10);

            })
        },

        
        addEditControlMap() {
            this.$nextTick(() => {

                const map = this.$refs.map.mapObject;

                const editableLayers = new window.L.FeatureGroup().addTo(map);
                const drawControl = new window.L.Control.Draw({
                    position: 'topright',
                    draw: {
                        polyline: false,
                        polygon: false,
                        rectangle: false,
                        circle: true,
                        marker: false,
                        circlemarker: false
                    },
                    edit: false
                });
                const drawEditOnly = new window.L.Control.Draw({
                    position: 'topright',
                    draw: false,
                    edit: {
                        featureGroup: editableLayers, //REQUIRED!!
                        remove: true
                    }
                });

                if (router.currentRoute.params.id) {
                    this.save = true
                    map.addControl(drawEditOnly);
                } else map.addControl(drawControl);

                map.on(window.L.Draw.Event.CREATED, (e) => {
                    e.layer.options.color = '#9c27b0'
                    const layer = e.layer;

                    
                    this.pointData.lat = 0
                    this.pointData.lng = 0
                    this.pointData.radius = 0

                    
                    
                    if (layer instanceof L.Circle) {
                        this.pointData.radius = layer.getRadius()
                        const latlng = layer.getLatLng()
                        this.pointData.lat = latlng.lat
                        this.pointData.lng = latlng.lng
                        

                    }
                    editableLayers.addLayer(layer);

                    //drawControl.removeFrom(map);
                    map.removeControl(drawControl);
                    map.addControl(drawEditOnly);

                    this.save = true

                    // Do whatever else you need to. (save to db, add to map etc)
                });

                map.on("draw:deleted", (e) => {

                    if (editableLayers.getLayers().length === 0) {

                        
                        this.pointData.lat = 0
                        this.pointData.lng = 0
                        this.pointData.radius = 0

                       

                        this.save = false
                        map.removeControl(drawEditOnly);
                        map.addControl(drawControl);
                    };
                });
            });
        },

        selectHandler(option) {
            this.selected = option.item
            this.filteredOptions = []
            this.searchQuery = option.item.name
            this.pointData.customerId = option.item.id

        },

        onInputChange(text) {
            if (text === '' || text === undefined || text.length < 3) {

                return
            }

            store.dispatch('app-point/fetchCustomers', {
                    q: this.searchQuery,

                })
                .then(response => {

                    this.filteredOptions = [{
                        data: response.data.data,
                    }]

                })
                .catch(error => {

                    console.log(error)

                })
        },
        suggestionSelected() {
            console.log('Selecionado', this.selected)
        },

        msgDialog(msg, icon) {
            this.$swal({
                position: 'top-end',
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 3,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },

                buttonsStyling: false,
            })
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (this.pointData.lat == 0 && this.pointData.lng == 0 && this.pointData.radius == 0) {
                        this.msgDialog(`${this.$t('Failed to save')}, ${this.$t('please draw point of interest')}`, 'error')
                        return
                    }

                    if (this.pointData.id === "") {

                        store.dispatch('app-point/addPoint', {
                                pointData: this.pointData
                            })
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog(this.$t('Successful registration'), 'success')
                                router.push({
                                    name: 'map'
                                })

                            })
                            .catch(error => {
                                this.msgDialog(this.$t('Failed to save'), 'error')
                                // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API
                            })
                    } else {
                        store.dispatch('app-point/updatePoint', {
                                pointData: this.pointData
                            })
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog(this.$t('Successful update'), 'success')
                                router.push({
                                    name: 'map'
                                })
                            })
                            .catch(error => {
                                this.msgDialog(this.$t('FAILURE to update'), 'error')

                            })
                    }
                }
            })
        },

        async fetchPoint() {
            await store.dispatch('app-point/fetchPoint', {
                    //id: router.currentRoute.params.id
                    id: router.currentRoute.params.id
                })
                .then(response => {
                    this.pointData = response.data
                   
                        this.drawCircle(response.data.lat, response.data.lng, response.data.radius)

                    
                })
                .catch(e => {
                    console.log(e)
                })
        }
    },
    setup() {

        //const fenceData = ref(null)
        //const searchQuery = ref(``)

        const POINT_APP_STORE_MODULE_NAME = 'app-point'
        const criterion = ref(null)

        // Register module
        if (!store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.registerModule(POINT_APP_STORE_MODULE_NAME, pointStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.unregisterModule(POINT_APP_STORE_MODULE_NAME)
        })

        const idRoute = router.currentRoute.params.id

        if (!idRoute && router.currentRoute.query.criterion.lat) {
            console.log('CRITERIO', router.currentRoute.query.criterion.lat)
            criterion.value = router.currentRoute.query.criterion
        }

        const center = criterion.value != null ? ref([criterion.value.lat, criterion.value.lng]) : ref([-13.6632305, -69.6410913])

        const zoom = criterion.value != null ? ref(criterion.value.zoom) : ref(4)

        return {

            center,
            zoom,
            //fenceData,
            //searchQuery
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style><style>
.leaflet-draw-draw-polygon {
    background: url("../../../assets/images/icons/polygon-icon.png") no-repeat !important;
    background-size: cover !important;
    background-color: #fff !important;
    justify-content: center !important;
    flex-direction: column !important;
    text-align: justify !important;
    max-width: 100% !important;
}

.leaflet-draw-draw-circle {
    background: url("../../../assets/images/icons/circle-icon.png") no-repeat !important;
    background-size: cover !important;
    background-color: #fff !important;
    justify-content: center !important;
    flex-direction: column !important;
    text-align: justify !important;
    max-width: 100% !important;
}

.leaflet-draw-edit-edit {
    background: url("../../../assets/images/icons/edit-icon.png") no-repeat !important;
    background-size: cover !important;
    background-color: #fff !important;
    justify-content: center !important;
    flex-direction: column !important;
    text-align: justify !important;
    max-width: 100% !important;
}

.leaflet-draw-edit-remove {
    background: url("../../../assets/images/icons/trash-icon.png") no-repeat !important;
    background-size: cover !important;
    background-color: #fff !important;
    justify-content: center !important;
    flex-direction: column !important;
    text-align: justify !important;
    max-width: 100% !important;
}

.leaflet-draw-draw-circlemarker {
    display: none !important;
}

.title-head {
    margin-bottom: 32px;
}
</style>
